<template>
    <v-app-bar
        color="primary"
        dense
        dark
        clipped-left
        app 
        >

       <v-btn
          icon
          dark
           @click="toogleMenu"
        >
          <v-icon>mdi-menu  </v-icon>
        </v-btn>
          

      <v-toolbar-title
        
        class="ml-6 h1"
        >SMAFARMI
      </v-toolbar-title>
        
 
      <v-spacer></v-spacer>
              
 
        <v-menu
          offset-y
          min-width="250"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon>mdi-account-circle</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{currentUser.name}}</v-list-item-title>
                <v-list-item-subtitle>{{currentUser.roleCaption}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
  

          <v-list>
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
            >
              <v-list-item-title>
                <v-btn
                  v-if="item.action && item.action != 'toRoute'"
                  text
                  @click="logOut"
                >
                      {{ item.title }}
                </v-btn>
                <v-btn
                  v-if="item.action == 'toRoute'"
                  text
                  :to="item.route"
                >
                      {{ item.title }}
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

    </v-app-bar>

</template>

 
<script>
import EventBus from '../../services/event-bus.service';

export default {
    components: {
    
    },

    props:{

    },

    data () {
        return {   
        
            items: [
            { title: 'Profil', action: "toRoute", route:"/profile" },
            { title: 'Settings', action: "toRoute", route:"/management" },
            { title: 'Logout', action: "logOut" },
            ],
        
        }
    },

    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        
        currentUser() {
        console.debug('currentUser',this.$store.state.auth.user)
        return this.$store.state.auth.user;
        }

    },

    methods:{


        logOut: function(){
            this.$store.dispatch('auth/logout');        
            this.$router.push('/');
        },

        toogleMenu: function(){
        EventBus.$emit('toggleMenu')
        },

    },

    mounted() {
        console.log('mounted', this.$route.params) 
   
    },    

}
</script>
