<template>


  <v-layout>
    <appbar></appbar>
    <navileft :menu="menu" :option="option.navileft"></navileft>    

    <!-- Sizes your content based upon application components -->
    <v-main>
        <!-- Provides the application the proper gutter -->
        <v-container fluid>
          <router-view :key="$route.fullPath"/>
        </v-container>
    </v-main>

    <v-footer app>
        <!-- -->
    </v-footer>

  </v-layout>
</template>

<script>
  
import appbar from '../components/appbars/AppBarManagement.vue'
import navileft from '../components/navbars/NavigationLeft.vue'

  export default {
    name: 'Management',

    components: {
      appbar,
      navileft,     
    },

    data () {
        return {
            option:{
                navileft:{
                    title:{
                        caption: "Management",
                        icon: "", 
                    }
                }
            }
        }
    },

    computed: {

        user(){
            return this.$store.state.auth.user
        },

        routeParams(){
            return this.$route.params
        },

        company() {
            return this.$store.state.app.actCompany.meta
        },

        menu(){
            console.debug('menu', this.$store.state.management.menu)
            return this.$store.state.management.menu
        },

        apiKeys() {
            return this.$store.state.app.apikeys
        },        
    },

    methods:{ 

        refresh(){     
            console.debug('refresh');    
            this.$store.dispatch("management/getApiSetStore", {path:"api/management/menu", param:{store:"menu"}
            }).catch((error)=>{
                console.error(error);
            })
        },

    },   

    mounted() {
        console.log('mounted') 
        this.refresh()
    },    

  }
</script>
